<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">Invoice
                <span class="notification is-danger is-light is-size-7 p-2"
                  v-if="isPnetAuthorityNoRequired && !innerValue.pnet.authorityNo">
                  PNET Authority No. is not present. Quote cannot be invoiced.
                </span>
              </p>
            </div>
            <div class="column is-narrow">
              <div v-if="!isLoadingInvoice"
                class="buttons">
                <a v-show="$user.hasEdit($route.meta.id) && !readOnlyView && !isUpdateInvoice && invoice && !invoice.gstPaidDate && !innerValue.readOnly"
                  class="button tooltip"
                  data-tooltip="Update Invoice"
                  @click="editInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-pencil mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasAdd($route.meta.id) && !invoice && !readOnlyView"
                  class="button tooltip"
                  data-tooltip="New Invoice"
                  @click="isPnetAuthorityNoRequired && !innerValue.pnet.authorityNo ? '' : addInvoice()"
                  :disabled="isPnetAuthorityNoRequired && !innerValue.pnet.authorityNo ? true : false">
                  <span class="icon has-text-success">
                    <i class="mdi mdi-plus mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasDelete($route.meta.id) && !readOnlyView && !isUpdateInvoice && invoice && !invoice.gstPaidDate && !innerValue.readOnly|| (invoice && invoice.isNew)"
                  class="button tooltip"
                  data-tooltip="Delete Invoice"
                  @click="deleteInvoice()">
                  <span class="icon has-text-danger">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
                <a v-show="isUpdateInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Update from quote"
                  @click="updateInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-refresh mdi-24px" />
                  </span>
                </a>
                <a v-show="isUpdateInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Cancel"
                  @click="cancelInvoice()">
                  <span class="icon has-text-warning">
                    <i class="mdi mdi-close mdi-24px" />
                  </span>
                </a>
              </div>
              <span v-if="invoice && invoice.gstPaidDate"
                class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
                data-tooltip="GST has been paid">GST paid on {{ $filters.formatDateTimezone(invoice.gstPaidDate, $userInfo.locale) }}</span>
            </div>
          </div>
          <div v-if="invoice && !isLoadingInvoice"
            class="field">
            <label v-if="quotesTotals.filter(q => q.isInvoiced === false || q.invoiceId === invoice.invoiceId).length > 0"
              class="label">Includes</label>
            <div class="control is-narrow">
              <div v-for="quote in quotesTotals.filter(q => q.isInvoiced === false || q.invoiceId === invoice.invoiceId)"
                :key="quote.subQuoteNo">
                <input type="checkbox"
                  class="is-checkradio"
                  :id="`Add${quote.subQuoteNo}`"
                  :checked="getIncludedStatus(quote)"
                  @click="includeQuote(quote, $event.target.checked)"
                  :disabled="!isUpdateInvoice || (isPnetAuthorityNoRequired && !quote.pnetAuthorityNo)">
                <label :for="`Add${quote.subQuoteNo}`">{{ quote.subQuoteNo === 0 ? 'Main' : `Add-${quote.subQuoteNo}` }}</label>
                <span class="notification is-danger is-light is-size-7 p-1"
                  v-if="isPnetAuthorityNoRequired && !quote.pnetAuthorityNo">PNET Authority No. is not present. {{ quote.subQuoteNo === 0 ? 'Main' : `Add-${quote.subQuoteNo}` }} cannot be invoiced.</span>
              </div>
            </div>
          </div>
          <div v-if="invoice && quotesTotals.filter(q => q.isInvoiced === false || q.invoiceId === invoice.invoiceId).length > 0"
            class="is-divider" />
          <div v-if="!invoice && isLoadingInvoice"
            class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
          <div v-else-if="invoice"
            style="height: 93%; align-self: stretch">
            <div class="field-body">
              <div class="field">
                <label class="label">Invoice No.</label>
                <div class="field has-addons">
                  <div class="control">
                    <input class="input is-static"
                      :value="invoiceNoReference"
                      type="text"
                      placeholder="Invoice No."
                      readonly>
                  </div>
                  <div v-if="isUpdateInvoice && ($userInfo.isSupportUser || $userInfo.isCustomerAdministrator)"
                    class="control">
                    <button class="button is-warning is-inverted tooltip is-tooltip-warning"
                      data-tooltip="Change invoice no."
                      @click="showChangeInvoiceNoModal">
                      <span class="icon is-medium">
                        <i class="mdi mdi-pencil-outline mdi-24px" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="!isUpdateInvoice"
                class="field">
                <label class="label">Invoice Date</label>
                <input class="input is-static"
                  :value=" $filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                  readonly>
              </div>
              <div v-else-if="$userInfo && !gstPaid && selectedInvoiceDate"
                class="field">
                <label class="label">Invoice Date</label>
                <v-date-picker v-model="selectedInvoiceDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  :min-date="firstDayOfMonth"
                  @input="invoiceDateInputEvent"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Invoice Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="is-divider" />
            <div v-if="!isUpdateInvoice"
              class="field">
              <label class="label">Payer</label>
              <input class="input is-static"
                :value="invoice.assetName"
                type="text"
                readonly>
            </div>
            <!-- <div v-if="isUpdateInvoice && invoice.invoicePayingType === invoicePayerTypes.ThirdParty"
              class="field">
              <div class="control is-pulled-right">
                <input class="is-checkradio is-small is-circle is-success is-rtl"
                  :class="{ 'has-background-color': assetFilter.showCustomer }"
                  id="chk-customer"
                  name="chk-customer"
                  type="checkbox"
                  v-model="assetFilter.showCustomer">
                <label for="chk-customer">Customer</label>
                <input class="is-checkradio is-small is-circle is-info is-rtl"
                  :class="{ 'has-background-color': assetFilter.showInsurer }"
                  id="chk-insurer"
                  name="chk-insurer"
                  type="checkbox"
                  v-model="assetFilter.showInsurer">
                <label for="chk-insurer">Insurer</label>
              </div>
            </div> -->
            <div v-if="isUpdateInvoice && invoice.invoicePayingType === invoicePayerTypes.ThirdParty"
              class="field">
              <div class="is-flex is-justify-content-space-between">
                <label class="label">Payer</label>
                <div class="is-flex">
                  <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                    <label class="pretty-checkbox-label pr-1 is-size-7"
                      for="chk-customer">Customer</label>
                    <div class="pretty p-icon p-round p-smooth m-0">
                      <input v-model="assetFilter.showCustomer"
                        id="chk-customer"
                        type="checkbox">
                      <div class="state p-success">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-align-items-center is-justify-content-end mb-2 pl-2">
                    <label class="pretty-checkbox-label pr-1 is-size-7"
                      for="chk-insurer">Insurer</label>
                    <div class="pretty p-icon p-round p-smooth m-0">
                      <input v-model="assetFilter.showInsurer"
                        id="chk-insurer"
                        type="checkbox">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="control is-expanded">
                <multiselect v-if="assets"
                  :class="{ 'is-danger' : $v.entity.invoices.$error}"
                  v-model="selectedAsset"
                  deselect-label="Can't remove this value"
                  track-by="assetId"
                  label="assetName"
                  placeholder="Select a payer"
                  :options="assets"
                  :loading="isAssetLoading"
                  :internal-search="false"
                  :options-limit="assetFilter.pageSize"
                  @select="selectAsset"
                  @remove="removeAsset"
                  @search-change="getAssetDropdownFilter"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true">
                  <template slot="option"
                    slot-scope="props">
                    <span class="tag is-pulled-right"
                      :class="{'is-success' : props.option.assetType == 1, 'is-info' : props.option.assetType == 4}">
                      {{ props.option.assetType == 1 ? 'Customer' : 'Insurer' }}
                    </span>
                    <span>{{ `${props.option.assetName}` }}</span>
                  </template>
                  <span class="has-text-danger"
                    slot="noResult">Payer not found.</span>
                </multiselect>
                <span class="help is-danger"
                  v-if="$v.entity && $v.entity.invoices.$error">Payer is required.</span>
              </div>
            </div>
            <div v-if="isUpdateInvoice"
              class="field">
              <label v-if="invoice.invoicePayingType !== invoicePayerTypes.ThirdParty"
                class="label">Payer</label>
              <div class="control">
                <div class="pretty p-default p-round p-bigger p-smooth">
                  <input type="radio"
                    name="rd-payerType1"
                    :value="invoicePayerTypes.Insurer"
                    v-model="invoice.invoicePayingType"
                    @click="onInvoicePayerTypeClick(invoicePayerTypes.Insurer)">
                  <div class="state p-primary-o">
                    <label>Insurer</label>
                  </div>
                </div>
                <div class="pretty p-default p-round p-bigger p-smooth">
                  <input type="radio"
                    name="rd-payerType1"
                    :value="invoicePayerTypes.Private"
                    v-model="invoice.invoicePayingType"
                    @click="onInvoicePayerTypeClick(invoicePayerTypes.Private)"
                    :disabled="!customerAsset">
                  <div class="state p-primary-o">
                    <label>Private</label>
                  </div>
                </div>
                <div class="pretty p-default p-round p-bigger p-smooth">
                  <input type="radio"
                    name="rd-payerType1"
                    :value="invoicePayerTypes.ThirdParty"
                    v-model="invoice.invoicePayingType"
                    @click="onInvoicePayerTypeClick(invoicePayerTypes.ThirdParty)">
                  <div class="state p-primary-o">
                    <label>3rd Party</label>
                  </div>
                </div>
                <!-- <input class="is-checkradio"
                  id="payerType1"
                  type="radio"
                  name="payerType1"
                  :value="invoicePayerTypes.Insurer"
                  v-model="invoice.invoicePayingType"
                  @click="onInvoicePayerTypeClick(invoicePayerTypes.Insurer)">
                <label for="payerType1">Insurer</label>
                <input class="is-checkradio"
                  id="payerType2"
                  type="radio"
                  name="payerType2"
                  :value="invoicePayerTypes.Private"
                  v-model="invoice.invoicePayingType"
                  @click="onInvoicePayerTypeClick(invoicePayerTypes.Private)"
                  :disabled="!customerAsset">
                <label for="payerType2">Private</label>
                <input class="is-checkradio"
                  id="payerType3"
                  type="radio"
                  name="payerType3"
                  :value="invoicePayerTypes.ThirdParty"
                  v-model="invoice.invoicePayingType"
                  @click="onInvoicePayerTypeClick(invoicePayerTypes.ThirdParty)">
                <label for="payerType3">3rd Party</label> -->
              </div>
              <span v-if="!customerAsset"
                class="help is-danger">Private invoice disabled. Customer has been deleted</span>
            </div>
            <div class="is-divider" />
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <!-- <div class="field">
                    <div class="control is-pulled-right">
                      <input class="is-checkradio is-small is-circle is-primary is-rtl"
                        :class="{ 'has-background-color': excessWithGst }"
                        id="chk-excessWtihGst"
                        name="chk-excessWtihGst"
                        type="checkbox"
                        v-model="excessWithGst"
                        :disabled="!isUpdateInvoice">
                      <label for="chk-excessWtihGst">Excess with GST</label>
                    </div>
                  </div> -->
                  <div class="field">
                    <div class="is-flex is-justify-content-space-between">
                      <label class="label">Excess</label>
                      <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                        <label class="pretty-checkbox-label pr-1 is-size-7"
                          for="chk-invoiceExcessWtihGst">Excess with GST</label>
                        <div class="pretty p-icon p-round p-smooth m-0">
                          <input id="chk-invoiceExcessWtihGst"
                            type="checkbox"
                            v-model="excessWithGst"
                            :disabled="!isUpdateInvoice">
                          <div class="state p-primary">
                            <i class="icon mdi mdi-check" />
                            <label />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-numeric class="input has-text-right"
                      v-model.number="invoice.excessAmount"
                      :min:="0"
                      :precision="2"
                      :disabled="!isUpdateInvoice || hasExcessInvoice"
                      @input="updateTotal()" />
                  </div>
                </div>
                <div class="field">
                  <label class="label">Owner Contribution</label>
                  <vue-numeric class="input has-text-right"
                    v-model.number="invoice.ownerContribution"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice || hasExcessInvoice"
                    @input="updateTotal()" />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <label class="label">Discount</label>
                  <vue-numeric class="input has-text-right"
                    v-model.number="invoice.quoteDiscount"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice"
                    @input="updateTotal()" />
                </div>
                <!-- <div class="field"> -->
                <!-- <div class="field">
                    <div class="control is-pulled-right">
                      <input class="is-checkradio is-small is-circle is-primary is-rtl"
                        :class="{ 'has-background-color': fixedGst }"
                        id="chk-fixed-gst"
                        name="chk-fixed-gst"
                        type="checkbox"
                        v-model="fixedGst"
                        :disabled="!isUpdateInvoice">
                      <label for="chk-fixed-gst">Fixed</label>
                    </div>
                  </div> -->
                <div class="field">
                  <div class="is-flex is-justify-content-space-between">
                    <label class="label">GST</label>
                    <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                      <label class="pretty-checkbox-label pr-1 is-size-7"
                        for="chk-fixed-gst">Fixed</label>
                      <div class="pretty p-icon p-round p-smooth m-0">
                        <input id="chk-fixed-gst"
                          type="checkbox"
                          v-model="fixedGst"
                          :disabled="!isUpdateInvoice">
                        <div class="state p-primary">
                          <i class="icon mdi mdi-check" />
                          <label />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model.number="invoice.gst"
                      :minus="true"
                      :precision="2"
                      :disabled="!isUpdateInvoice || isGstInvoiceOn" />
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input type="checkbox"
                  v-model="invoice.exported"
                  class="is-checkradio"
                  id="invoice-exported"
                  :disabled="readOnlyView || !$userInfo.isSupportUser || !isUpdateInvoice">
                <label for="invoice-exported">Exported</label>
              </div>
            </div>
            <div class="field">
              <label class="label">Total Excl GST: <span class="has-text-success">{{ totalExGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
            <div class="field">
              <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ totalPayableIncGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
            <div class="is-divider" />
            <div v-if="invoice && !isLoadingInvoice"
              class="field">
              <div class="control is-narrow">
                <input type="checkbox"
                  v-model="isGstInvoiceOn"
                  class="is-checkradio"
                  :id="`gstInvoice`"
                  :disabled="!isUpdateInvoice"
                  @click="toggleGstInvoice($event.target.checked)">
                <label :for="`gstInvoice`">GST Invoice</label>
              </div>
            </div>
            <div v-if="isUpdateInvoice && isGstInvoiceOn"
              class="field">
              <div class="control is-pulled-right">
                <input class="is-checkradio is-small is-circle is-success is-rtl"
                  :class="{ 'has-background-color': gstAssetFilter.showCustomer }"
                  id="chk-gstCustomer"
                  name="chk-gstCustomer"
                  type="checkbox"
                  v-model="gstAssetFilter.showCustomer">
                <label for="chk-gstCustomer">Customer</label>
                <input class="is-checkradio is-small is-circle is-info is-rtl"
                  :class="{ 'has-background-color': gstAssetFilter.showInsurer }"
                  id="chk-gstInsurer"
                  name="chk-gstInsurer"
                  type="checkbox"
                  v-model="gstAssetFilter.showInsurer">
                <label for="chk-gstInsurer">Insurer</label>
              </div>
            </div>
            <div v-if="isUpdateInvoice && isGstInvoiceOn"
              class="field">
              <label class="label">Payer</label>
              <div class="control is-expanded">
                <multiselect v-if="assets"
                  :class="{ 'is-danger' : $v.entity.invoices.$error}"
                  v-model="selectedGstAsset"
                  deselect-label="Can't remove this value"
                  track-by="assetId"
                  label="assetName"
                  placeholder="Select a payer"
                  :options="gstAssets"
                  :loading="isGstAssetLoading"
                  :internal-search="false"
                  :options-limit="gstAssetFilter.pageSize"
                  @select="selectGstAsset"
                  @remove="removeGstAsset"
                  @search-change="getGstAssetDropdownFilter"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true">
                  <template slot="option"
                    slot-scope="props">
                    <span class="tag is-pulled-right"
                      :class="{'is-success' : props.option.assetType == 1, 'is-info' : props.option.assetType == 4}">
                      {{ props.option.assetType == 1 ? 'Customer' : 'Insurer' }}
                    </span>
                    <span>{{ `${props.option.assetName}` }}</span>
                  </template>
                  <span class="has-text-danger"
                    slot="noResult">Payer not found.</span>
                </multiselect>
                <span class="help is-danger"
                  v-if="$v.entity && $v.entity.invoices.$error">Payer is required.</span>
              </div>
            </div>
            <div v-else-if="!isUpdateInvoice && gstInvoice">
              <label class="label">Payer</label>
              <span>{{ gstInvoice.assetName }}</span>
            </div>
            <div v-if="$userInfo.isSupportUser && gstInvoice"
              class="field">
              <div class="control">
                <input type="checkbox"
                  v-model="gstInvoice.exported"
                  class="is-checkradio"
                  id="gstInvoice-exported"
                  :disabled="!isUpdateInvoice">
                <label for="gstInvoice-exported">Exported</label>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Remarks &amp; Overrides</p>
        <div v-if="invoice && !isLoadingInvoice">
          <div class="field">
            <label class="label">Remark</label>
            <div class="control">
              <textarea class="textarea"
                v-model="invoice.invoiceRemark"
                placeholder=""
                :disabled="!isUpdateInvoice" />
            </div>
          </div>
          <div class="is-divider" />
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">Total Labour</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.labourTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">R &amp; R</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.rrTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
              <div class="field">
                <label class="label">Repair</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.repairTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">RWA</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.rwaTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
              <div class="field">
                <label class="label">Paint</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.paintTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">FG</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.fgTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
              <div class="field">
                <label class="label">Crush</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.crushTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">CD</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.cdTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
              <div class="field">
                <label class="label">Mech</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.mechTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="innerValue.quoteType === quoteTypes.Pdr"
            class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">PDRRR</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.pdrRrTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
              <div class="field">
                <label class="label">PDRRE</label>
                <div class="control">
                  <vue-numeric class="input has-text-right"
                    v-model="overrides.pdrReTotal"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateInvoice" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <invoice-no-search-modal v-if="isInvoiceNoSearchModalActive"
      :active.sync="isInvoiceNoSearchModalActive"
      :invoice-type="1"
      @ok="changeInvoiceNo"
      @close="isInvoiceNoSearchModalActive = false" />
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService.js'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import _debounce from 'lodash.debounce'
import { QuoteInvoiceService } from './services'
import { AssetTypes, InvoiceTypes, InvoicePayerTypes, EventHubTypes, QuoteTypes, JobStageTypes } from '@/enums'
import QuoteRoutes from './route-types'
import DeepDiff from 'deep-diff'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteInvoiceStoreMixin from './mixins/QuoteInvoiceStoreMixin'
import QuoteInvoiceValidationMixin from './mixins/QuoteInvoiceValidationMixin'
import QuoteService from './QuoteService'
import { OverrideMappings } from './QuoteService'
import { InvoiceNoSearchModal } from './components'

export default {
  name: 'QuoteInvoice',
  components: {
    VueNumeric,
    Multiselect,
    InvoiceNoSearchModal
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, QuoteInvoiceStoreMixin, QuoteInvoiceValidationMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      gstPaid: false,
      excess: this.value.excess,
      ownerContribution: this.value.ownerContribution,
      discount: this.value.discount,
      gst: roundAwayFromZero((this.value.totalExGst * this.value.gstRate) / 100),
      excessWithGst: this.value.excessWithGst,
      overrides: {
        labourTotal: 0,
        rrTotal: 0,
        repairTotal: 0,
        rwaTotal: 0,
        paintTotal: 0,
        fgTotal: 0,
        crushTotal: 0,
        cdTotal: 0,
        mechTotal: 0,
        pdrRrTotal: 0,
        pdrReTotal: 0
      },
      selectedAsset: null,
      assets: [],
      isAssetLoading: false,
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      invoice: null,
      tabInvoiceType: InvoiceTypes.Quote,
      isLoadingInvoice: false,
      // isUpdateInvoice: false,
      // baseInvoice: null,
      insurerAsset: null,
      customerAsset: null,
      quotesTotals: [],
      gstInvoice: null,
      isGstInvoiceOn: false,
      selectedGstAsset: null,
      gstAssets: [],
      isGstAssetLoading: false,
      gstAssetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      selectedInvoiceDate: null,
      isInvoiceNoSearchModalActive: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    },
    invoiceTypes() {
      return InvoiceTypes
    },
    isInvoiced() {
      return this.invoice !== null
    },
    invoicePayerTypes() {
      return InvoicePayerTypes
    },
    totalExGst() {
      return this.invoice.totalExGst
    },
    totalIncGst() {
      return this.invoice.totalIncGst
    },
    totalPayableIncGst() {
      return this.invoice.totalIncGst
    },
    showAsset() {
      return {
        customer: this.assetFilter.showCustomer,
        insurer: this.assetFilter.showInsurer
      }
    },
    invoiceNoReference() {
      return this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : ''
    },
    isNewInvoice() {
      return this.invoice && this.invoice.isNew
    },
    isDirty() {
      const diff = DeepDiff.diff(this.baseInvoice, this.invoice, (path, key) => path.length === 0 && ~['isDirty'].indexOf(key))
      return diff !== undefined
    },
    isQuoteTotalDirty() {
      const baseSnapshot = _cloneDeep(this.baseSnapshot)
      const currentSnapshot = _cloneDeep(this.currentSnapshot)
      return baseSnapshot.totalExGst !== currentSnapshot.totalExGst
    },
    isPnetAuthorityNoRequired() {
      return !this.innerValue.isAudaBridge && this.innerValue.isPnet
    },
    invoiceValues() {
      if (this.invoice) {
        return this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.quoteDiscount
      } else {
        return 0
      }
    },
    overrideValues() {
      if (this.invoice) {
        return (
          this.invoice.rrOverride +
          this.invoice.repairOverride +
          this.invoice.paintOverride +
          this.invoice.rwaOverride +
          this.invoice.fgOverride +
          this.invoice.crushOverride +
          this.invoice.cdOverride +
          this.invoice.mechOverride +
          this.invoice.pdrRrOverride +
          this.invoice.pdrReOverride +
          this.invoice.labourTotal
        )
      } else {
        return 0
      }
    },
    hasExcessInvoice() {
      const index = this.innerValue.invoices.findIndex((i) => i.invoiceType === InvoiceTypes.Excess && !i.deleted)
      return index >= 0
    },
    quotesLabourTotal() {
      return roundAwayFromZero(this.innerValue.labourTotal + this.includedQuotesLabourTotal)
    },
    quotesRrTotal() {
      return roundAwayFromZero(this.innerValue.rrTotal + this.includedQuotesRrTotal)
    },
    quotesRepairTotal() {
      return roundAwayFromZero(this.innerValue.repairTotal + this.includedQuotesRepairTotal)
    },
    quotesRwaTotal() {
      return roundAwayFromZero(this.innerValue.rwaTotal + this.includedQuotesRwaTotal)
    },
    quotesPaintTotal() {
      return roundAwayFromZero(this.innerValue.paintTotal + this.includedQuotesPaintTotal)
    },
    quotesFgTotal() {
      return roundAwayFromZero(this.innerValue.fgTotal + this.includedQuotesFgTotal)
    },
    quotesCrushTotal() {
      return roundAwayFromZero(this.innerValue.crushTotal + this.includedQuotesCrushTotal)
    },
    quotesCdTotal() {
      return roundAwayFromZero(this.innerValue.cdTotal + this.includedQuotesCdTotal)
    },
    quotesMechTotal() {
      return roundAwayFromZero(this.innerValue.mechTotal + this.includedQuotesMechTotal)
    },
    quotesOpgTotal() {
      return roundAwayFromZero(this.innerValue.opgTotal + this.includedQuotesOpgTotal)
    },
    quotesPartTotal() {
      return roundAwayFromZero(this.innerValue.partTotal + this.includedQuotesPartTotal)
    },
    quotesMiscTotal() {
      return roundAwayFromZero(this.innerValue.miscTotal + this.includedQuotesMiscTotal)
    },
    quotesSubletTotal() {
      return roundAwayFromZero(this.innerValue.sublTotal + this.includedQuotesSubletTotal)
    },
    hasOverrides() {
      const vm = this
      return Object.keys(this.overrides).some(function (key, index) {
        return vm.overrides[key] !== 0
      })
    },
    overrideKeys() {
      const vm = this
      return Object.keys(this.overrides).filter(function (o) {
        return vm.overrides['labourTotal'] === 0 ? o !== 'labourTotal' : o !== 'rrTotal' && o !== 'repairTotal' && o !== 'rwaTotal' && o !== 'paintTotal'
      })
    },
    quotesTotalExGst() {
      const vm = this
      // const hasOverrides = Object.keys(this.overrides).some(function(key, index) {
      //   return vm.overrides[key] !== 0
      // })
      if (!this.hasOverrides) {
        return roundAwayFromZero(this.innerValue.totalExGst + this.includedQuotesTotalExGst)
      } else {
        let quotesTotalExGst = this.innerValue.partTotal + this.innerValue.miscTotal + this.innerValue.sublTotal + this.innerValue.opgTotal
        quotesTotalExGst += this.includedQuotesPartTotal + this.includedQuotesMiscTotal + this.includedQuotesSubletTotal + this.includedQuotesOpgTotal
        // console.log('quotesTotalExGst', quotesTotalExGst)
        this.overrideKeys.forEach(function (key, index) {
          // console.log(key, vm.overrides[key])
          if (vm.overrides[key] !== 0) {
            quotesTotalExGst += vm.overrides[key]
            // console.log('override', key, quotesTotalExGst, vm.overrides[key])
          } else {
            const includedQuotesTotal = vm.invoiceIncludedQuotes.reduce(function (total, quote) {
              // console.log('quote[key]', quote, quote[key])
              return roundAwayFromZero(total + (quote[key] !== undefined ? quote[key] : 0))
            }, 0)
            quotesTotalExGst += vm.innerValue[key] + includedQuotesTotal
            // console.log('override 1', key, quotesTotalExGst, vm.innerValue[key])
          }
        })
        return quotesTotalExGst
      }
    },
    quotesTotalIncGst() {
      return roundAwayFromZero(this.innerValue.totalIncGst + this.includedQuotesTotalIncGst)
    },
    quotesExcessTotal() {
      return roundAwayFromZero(this.innerValue.excess + this.includedQuotesExcessTotal)
    },
    quotesOwnerContributionTotal() {
      return roundAwayFromZero(this.innerValue.ownersContribution + this.includedQuotesOwnerContributionTotal)
    },
    quotesDiscountTotal() {
      return roundAwayFromZero(this.innerValue.discount + this.includedQuotesDiscountTotal)
    },
    includedQuotesLabourTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.labourTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesRrTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.rrTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesRepairTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.repairTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesRwaTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.rwaTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesPaintTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.paintTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesFgTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.fgTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesCrushTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.crushTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesCdTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.cdTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesMechTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.mechTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesOpgTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.opgTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesPartTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.partTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesMiscTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.miscTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesSubletTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.sublTotal)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesTotalExGst() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.totalExGst)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesTotalIncGst() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.totalIncGst)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesExcessTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + (quote.subQuoteNo === 0 ? quote.excess : 0))
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesOwnerContributionTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.ownerContribution)
        }, 0)
      } else {
        return 0
      }
    },
    includedQuotesDiscountTotal() {
      if (this.invoiceIncludedQuotes.length > 0) {
        return this.invoiceIncludedQuotes.reduce(function (total, quote) {
          return roundAwayFromZero(total + quote.discount)
        }, 0)
      } else {
        return 0
      }
    },
    baseInvoice() {
      if (this.baseSnapshot) {
        const baseInvoice = this.baseSnapshot.invoices.find((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
        return _cloneDeep(baseInvoice)
      }
    },
    quoteTypes() {
      return QuoteTypes
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.invoice.isNew) {
        invoiceDate = new Date(`${this.invoice.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
    // invoiceGst() {
    //   if (!this.innerValue.excessWithGst) {
    //     return roundAwayFromZero(((this.quotesTotalExGst - this.invoice.quoteDiscount) * this.innerValue.gstRate) / 100)
    //   } else {
    //     return roundAwayFromZero((this.invoice.totalExGst * this.invoice.gstRate) / 100)
    //   }
    // }
    // gstChanged() {
    //   if (this.invoice && this.baseInvoice) {
    //     // return this.invoice.gst !== this.baseInvoice.gst
    //     return this.gst !== this.invoice.gst
    //   } else {
    //     return false
    //   }
    // }
  },
  watch: {
    // innerValue: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // },
    showAsset(newVal, oldVal) {
      if (!newVal.customer && !newVal.insurer) {
        this.assetFilter.showCustomer = !oldVal.customer
        this.assetFilter.showInsurer = !oldVal.insurer
      }
      this.getAssetDropdown()
    },
    excessWithGst(newVal, oldVal) {
      if (this.isUpdateInvoice) {
        // Reset fixedGst
        this.fixedGst = false
        if (newVal) {
          const quotetotalExGst = this.quotesTotalExGst - this.invoice.quoteDiscount - this.invoice.ownerContribution
          const gst = roundAwayFromZero((quotetotalExGst * this.innerValue.gstRate) / 100)
          this.invoice.gst = roundAwayFromZero(gst - (this.invoice.excessAmount * this.innerValue.gstRate) / 100)
        } else {
          const quotetotalExGst = this.quotesTotalExGst - this.invoice.quoteDiscount
          const gst = roundAwayFromZero((quotetotalExGst * this.innerValue.gstRate) / 100)
          this.invoice.gst = gst
        }
        this.innerValue.excessWithGst = newVal
      }
    },
    invoiceValues() {
      if (this.invoice && this.isUpdateInvoice) {
        // console.log('watch invoiceValues')
        this.recalculateTotals()
      }
    },
    'overrides.labourTotal': function (newVal, oldVal) {
      if (newVal !== 0 && this.isUpdateInvoice) {
        this.overrides.rrTotal = 0
        this.overrides.repairTotal = 0
        this.overrides.rwaTotal = 0
        this.overrides.paintTotal = 0
      }
    },
    'overrides.rrTotal': function (newVal, oldVal) {
      if (newVal !== 0 && this.isUpdateInvoice) {
        this.overrides.labourTotal = 0
      }
    },
    'overrides.repairTotal': function (newVal, oldVal) {
      if (newVal !== 0 && this.isUpdateInvoice) {
        this.overrides.labourTotal = 0
      }
    },
    'overrides.rwaTotal': function (newVal, oldVal) {
      if (newVal !== 0 && this.isUpdateInvoice) {
        this.overrides.labourTotal = 0
      }
    },
    'overrides.paintTotal': function (newVal, oldVal) {
      if (newVal !== 0 && this.isUpdateInvoice) {
        this.overrides.labourTotal = 0
      }
    },
    overrides: {
      handler: function () {
        if (this.invoice && this.isUpdateInvoice) {
          const vm = this
          Object.keys(this.overrides).forEach(function (key, index) {
            vm.invoice[OverrideMappings[key]] = vm.overrides[key]
          })
          this.recalculateTotals()
        }
      },
      deep: true
    },
    'invoice.excessAmount': function (newVal, oldVal) {
      // console.log(newVal)
      if (this.isUpdateInvoice && newVal !== undefined && !this.hasExcessInvoice) {
        this.innerValue.excess = newVal
      }
    },
    'invoice.ownerContribution': function (newVal, oldVal) {
      if (this.isUpdateInvoice && newVal !== undefined && !this.hasExcessInvoice) {
        this.innerValue.ownersContribution = newVal
      }
    },
    'invoice.quoteDiscount': function (newVal, oldVal) {
      if (this.isUpdateInvoice && newVal !== undefined) {
        this.invoice.sdTotals = newVal
      }
    },
    'invoice.gst': function (newVal, oldVal) {
      if (this.invoice && this.isUpdateInvoice) {
        if (!this.innerValue.excessWithGst) {
          const ownerContributionGst = roundAwayFromZero((this.invoice.ownerContribution * this.invoice.gstRate) / 100)
          const quotesTotalExGst = roundAwayFromZero(
            this.quotesTotalExGst - this.invoice.quoteDiscount - this.invoice.excessAmount - this.invoice.ownerContribution - ownerContributionGst
          )
          this.invoice.totalExGst = quotesTotalExGst
          this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
        } else {
          this.invoice.totalExGst = roundAwayFromZero(
            this.quotesTotalExGst - this.invoice.excessAmount - this.invoice.quoteDiscount - this.invoice.ownerContribution
          )
          this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
        }
      }
    },
    isDirty(newVal) {
      if (this.invoice && this.isUpdateInvoice) {
        this.invoice.isDirty = newVal
      }
    },
    'invoice.invoicePayingType': function (newVal, oldVal) {
      // if (!oldVal || !this.invoice) {
      //   return
      // }
      // console.log('watch invoice.invoicePayingType', newVal, oldVal)
      // if (this.invoice.invoicePayingType === InvoicePayerTypes.Insurer && this.insurerAsset) {
      //   this.invoice.assetId = this.insurerAsset.assetId
      //   this.invoice.assetName = this.insurerAsset.assetName
      //   this.invoice.assetType = this.insurerAsset.assetType
      //   this.assetFilter.assetId = Guid.empty()
      //   this.selectedAsset = null
      // } else if (this.invoice.invoicePayingType === InvoicePayerTypes.Private && this.customerAsset) {
      //   this.invoice.assetId = this.customerAsset.assetId
      //   this.invoice.assetName = this.customerAsset.assetName
      //   this.invoice.assetType = this.customerAsset.assetType
      //   this.assetFilter.assetId = Guid.empty()
      //   this.selectedAsset = null
      // } else if (this.invoice.invoicePayingType === InvoicePayerTypes.ThirdParty) {
      //   this.invoice.assetId = Guid.empty()
      //   this.invoice.assetName = ''
      //   this.invoice.assetType = ''
      // }
    },
    includedQuotesTotalExGst(newVal, oldVal) {
      if (this.innerValue && this.invoice && this.currentSnapshot && this.isUpdateInvoice) {
        // console.log('includedQuotesTotalExGst', this.currentSnapshot)
        this.updateInvoice()
      }
    },
    selectedInvoiceDate: function (newVal, oldVal) {
      if (newVal && this.isUpdateInvoice) {
        this.invoice.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
        if (this.gstInvoice) {
          this.gstInvoice.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
        }
      } else if (this.isUpdateInvoice) {
        this.invoice.invoiceDate = null
        if (this.gstInvoice) {
          this.gstInvoice.invoiceDate = null
        }
      }
    }
  },
  created() {
    this.$v.entity.invoices.$touch()
    this.getIncludedQuotesTotals()
    this.getAssets()
    this.getInvoice()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.reloadInvoice()
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    async getInvoice(cancel = false) {
      // console.log('getInvoice', cancel)
      this.isLoadingInvoice = true
      const baseSnapshot = _cloneDeep(this.baseSnapshot)
      // this.baseInvoice = _cloneDeep(baseSnapshot.invoices.find(i => i.invoiceType === InvoiceTypes.Quote && !i.deleted))
      if (!cancel) {
        this.invoice = this.innerValue.invoices.find((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
        this.gstInvoice = this.innerValue.invoices.find((i) => i.invoiceType === InvoiceTypes.Gst && !i.deleted)
        this.isGstInvoiceOn = this.gstInvoice && true
      } else {
        // const currentSnapshot = _cloneDeep(this.currentSnapshot)
        this.innerValue.excess = baseSnapshot.excess
        this.innerValue.ownersContribution = baseSnapshot.ownersContribution
        this.innerValue.discount = baseSnapshot.discount
        this.innerValue.excessWithGst = baseSnapshot.excessWithGst
        this.innerValue.quoteChanged = baseSnapshot.quoteChanged
        this.excessWithGst = this.innerValue.excessWithGst
        for (var key in this.baseInvoice) {
          if (this.baseInvoice.hasOwnProperty(key)) {
            this.invoice[key] = this.baseInvoice[key]
          }
        }
        // Reset GST Invoice if any
        this.gstInvoice = this.innerValue.invoices.find((i) => i.invoiceType === InvoiceTypes.Gst && !i.deleted)
        if (this.gstInvoice && this.gstInvoice.isNew) {
          this.toggleGstInvoice(false)
        }
        this.isGstInvoiceOn = this.gstInvoice && true
      }
      if (this.invoice) {
        if (this.invoice.invoicePayingType === InvoicePayerTypes.ThirdParty) {
          // Set selectedAsset
          this.assetFilter.assetId = this.invoice.assetId
          this.getAssetDropdown()
        }
        this.selectedInvoiceDate = new Date(`${this.invoice.invoiceDate}`)
        if (this.isUpdateInvoice) {
          this.updateInvoice()
        } else {
          // console.log(this.invoice.gst, this.calculateGst())
          this.fixedGst = this.invoice.gst !== this.calculateGst()
        }
      }
      if (this.gstInvoice) {
        this.gstAssetFilter.assetId = this.gstInvoice.assetId
        this.getGstAssetDropdown()
      }

      this.isLoadingInvoice = false
    },
    calculateGst() {
      const ownerContributionGst = roundAwayFromZero((this.invoice.ownerContribution * this.invoice.gstRate) / 100)
      if (!this.innerValue.excessWithGst) {
        return roundAwayFromZero(
          ((this.invoice.totalExGst + this.invoice.excessAmount + this.invoice.ownerContribution + ownerContributionGst) * this.innerValue.gstRate) / 100
        )
      } else {
        return roundAwayFromZero((this.invoice.totalExGst * this.innerValue.gstRate) / 100)
      }
    },
    async getAssets() {
      const insurerAssetFilter = {
        assetId: this.innerValue.insurerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      const customerAssetFilter = {
        assetId: this.innerValue.customerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      let insurers
      let customers
      ;[insurers, customers] = await Promise.all([
        QuickInvoiceService.getAssetDropdownProto(insurerAssetFilter),
        QuickInvoiceService.getAssetDropdownProto(customerAssetFilter)
      ])
      this.insurerAsset = insurers[0]
      this.customerAsset = customers[0]
    },
    async getAssetDropdown() {
      this.isAssetLoading = true
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      if (this.assetFilter.assetId !== Guid.empty()) {
        this.selectedAsset = this.assets.find((c) => c.assetId === this.assetFilter.assetId)
      }
      this.isAssetLoading = false
    },
    getAssetDropdownFilter: _debounce(async function (query) {
      this.isAssetLoading = true
      this.assetFilter.assetName = query
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      this.isAssetLoading = false
    }, 500),
    async selectAsset(selected) {
      this.invoice.assetId = selected.assetId
      this.invoice.assetType = selected.assetType
      this.invoice.invoiceGlCode = selected.assetGlCode
      this.invoice.assetName = selected.assetName
      this.assetFilter.assetId = selected.assetId
    },
    removeAsset(removed) {
      this.assetFilter.assetId = Guid.empty()
      this.invoice.assetType = ''
      this.invoice.invoiceGlCode = ''
      this.invoice.assetId = Guid.empty()
      this.invoice.assetName = ''
      this.getAssetDropdown()
    },
    tabClicked(invoiceType) {
      this.tabInvoiceType = invoiceType
    },
    editInvoice() {
      this.updateInvoice()
      // this.isUpdateInvoice = true
      this.setIsUpdateInvoice(true)
    },
    updateInvoice(reset = true) {
      // console.log('updateInvoice, reset:', reset)
      this.excessWithGst = this.innerValue.excessWithGst
      this.invoice.gstRate = this.innerValue.gstRate

      this.invoice.labourTotal = this.quotesLabourTotal
      this.invoice.rrTotal = this.quotesRrTotal
      this.invoice.repairTotal = this.quotesRepairTotal
      this.invoice.rwaTotals = this.quotesRwaTotal
      this.invoice.paintTotal = this.quotesPaintTotal

      this.invoice.fgTotal = this.quotesFgTotal
      this.invoice.crushTotal = this.quotesCrushTotal
      this.invoice.cdTotal = this.quotesCdTotal
      this.invoice.mechTotal = this.quotesMechTotal

      this.invoice.opgTotal = this.quotesOpgTotal
      this.invoice.partTotal = this.quotesPartTotal
      this.invoice.miscTotal = this.quotesMiscTotal
      this.invoice.subletTotal = this.quotesSubletTotal

      if (reset) {
        this.invoice.excessAmount = this.quotesExcessTotal
        this.invoice.ownerContribution = this.quotesOwnerContributionTotal
        this.invoice.quoteDiscount = this.quotesDiscountTotal //this.innerValue.discount
      }

      this.recalculateTotals(reset)
      this.innerValue.quoteChanged = false
      this.invoice.isDirty = true
    },
    recalculateTotals(reset = true) {
      if (!this.innerValue.excessWithGst) {
        const ownerContributionGst = roundAwayFromZero((this.invoice.ownerContribution * this.invoice.gstRate) / 100)
        const quotesTotalExGst = roundAwayFromZero(
          this.quotesTotalExGst - this.invoice.quoteDiscount - this.invoice.excessAmount - this.invoice.ownerContribution - ownerContributionGst
        )
        this.invoice.totalExGst = quotesTotalExGst
        if (reset && !this.isFixedGst) {
          this.invoice.gst = roundAwayFromZero(((this.quotesTotalExGst - this.invoice.quoteDiscount) * this.innerValue.gstRate) / 100)
        }
        this.invoice.totalIncGst = roundAwayFromZero(this.invoice.totalExGst + this.invoice.gst)
      } else {
        this.invoice.totalExGst = roundAwayFromZero(
          this.quotesTotalExGst - this.invoice.excessAmount - this.invoice.quoteDiscount - this.invoice.ownerContribution
        )
        if (reset && !this.isFixedGst) {
          this.invoice.gst = roundAwayFromZero((this.invoice.totalExGst * this.invoice.gstRate) / 100)
        }
        this.invoice.totalIncGst = this.invoice.totalExGst + this.invoice.gst
      }
    },
    cancelInvoice() {
      // this.isUpdateInvoice = false
      this.fixedGst = false
      this.resetOverrides()
      this.setIsUpdateInvoice(false)
      this.getIncludedQuotesTotals()
      this.getInvoice(true)
    },
    async addInvoice() {
      // this.isUpdateInvoice = true
      this.innerValue.jobStage = JobStageTypes.Closed
      this.setIsUpdateInvoice(true)
      this.isLoadingInvoice = true
      this.invoice = await QuoteInvoiceService.getNewEntity()
      this.existingInvoice = this.innerValue.invoices.find((i) => !i.isNew && !i.deleted)
      if (this.existingInvoice) {
        this.invoice.invoiceNo = this.existingInvoice.invoiceNo
      }

      // New invoice values
      this.updateInvoice(true)
      this.invoice.invoiceType = InvoiceTypes.Quote
      this.invoice.assetId = this.innerValue.insurerId
      this.invoice.assetType = AssetTypes.Insurer
      this.invoice.invoicePayingType = InvoicePayerTypes.Insurer
      this.selectedInvoiceDate = new Date() //new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

      this.invoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, this.innerValue.quoteId)

      this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.invoice)
      this.gstPaid = false

      this.getIncludedQuotesTotals()

      this.isLoadingInvoice = false
    },
    async deleteInvoice() {
      if (this.invoice.isNew) {
        const index = this.innerValue.invoices
          .map(function (obj) {
            return obj.invoiceId
          })
          .indexOf(this.invoice.invoiceId)
        this.innerValue.invoices.splice(index, 1)
        this.invoice = null
      } else {
        this.invoice.deleted = true
        this.invoice.isDeleted = true
      }
      if (this.gstInvoice) {
        if (this.gstInvoice.isNew) {
          const index = this.innerValue.invoices
            .map(function (obj) {
              return obj.invoiceId
            })
            .indexOf(this.gstInvoice.invoiceId)
          this.innerValue.invoices.splice(index, 1)
          this.gstInvoice = null
        } else {
          this.gstInvoice.deleted = true
          this.gstInvoice.isDeleted = true
        }
        this.gstAssetFilter.assetId = Guid.empty()
        this.selectedGstAsset = null
      }
      const baseSnapshot = _cloneDeep(this.baseSnapshot)
      this.innerValue.jobStage = baseSnapshot.jobStage
      this.isGstInvoiceOn = false
      this.fixedGst = false
      this.resetOverrides()
      this.setIsUpdateInvoice(false)
      await this.getIncludedQuotesTotals(true)
      this.getInvoice()
    },
    updateTotal(updateGst = true) {
      // if (this.isUpdateInvoice) {
      //   this.updateInvoice(false)
      //   const quotetotalExGst = this.quotesTotalExGst - this.invoice.quoteDiscount
      //   if (updateGst) {
      //     this.invoice.gst = roundAwayFromZero((quotetotalExGst * this.innerValue.gstRate) / 100)
      //   }
      //   const ownerContributionIncGst = roundAwayFromZero(
      //     this.invoice.ownerContribution + roundAwayFromZero((this.invoice.ownerContribution * this.invoice.gstRate) / 100)
      //   )
      //   this.invoice.totalIncGst = roundAwayFromZero(quotetotalExGst + this.invoice.gst - this.invoice.excessAmount - ownerContributionIncGst)
      //   this.invoice.totalExGst = roundAwayFromZero(this.invoice.totalIncGst - this.invoice.gst)
      // }
    },
    onInvoicePayerTypeClick(payerType) {
      if (payerType === InvoicePayerTypes.Insurer && this.insurerAsset) {
        // console.log('Insurer')
        this.invoice.assetId = this.insurerAsset.assetId
        this.invoice.assetName = this.insurerAsset.assetName
        this.invoice.assetType = this.insurerAsset.assetType
        this.invoice.invoiceGlCode = this.insurerAsset.assetGlCode
        this.assetFilter.assetId = Guid.empty()
        this.selectedAsset = null
      } else if (payerType === InvoicePayerTypes.Private && this.customerAsset) {
        this.invoice.assetId = this.customerAsset.assetId
        this.invoice.assetName = this.customerAsset.assetName
        this.invoice.assetType = this.customerAsset.assetType
        this.invoice.invoiceGlCode = this.customerAsset.assetGlCode
        this.assetFilter.assetId = Guid.empty()
        this.selectedAsset = null
      } else if (payerType === InvoicePayerTypes.ThirdParty) {
        // console.log('ThirdParty')
        this.invoice.assetId = Guid.empty()
        this.invoice.assetName = ''
        this.invoice.assetType = ''
        this.getAssetDropdown()
      }
    },
    async getIncludedQuotesTotals(isDelete = false) {
      if (this.innerValue.subQuoteNos.length > 1) {
        const quoteIds = this.innerValue.subQuoteNos.filter((q) => q.key !== this.innerValue.subQuoteNo).map((q) => q.value)

        const vm = this
        let quotesTotals = await QuoteService.getIncludedQuotesTotals(quoteIds)
        this.quotesTotals.splice(0, this.quotesTotals.length)
        quotesTotals.forEach(function (quoteTotal) {
          // console.log(quoteTotal)
          const invoice = vm.innerValue.invoices.find((i) => i.invoiceId === quoteTotal.invoiceId)
          if (invoice && quoteTotal.invoiceId === invoice.invoiceId && invoice.deleted) {
            quoteTotal.isInvoiced = false
            quoteTotal.invoiceNo = ''
            quoteTotal.invoiceId = Guid.empty()
          }
          vm.quotesTotals.splice(quotesTotals.length, 1, quoteTotal)
        })

        if (!this.isUpdateInvoice || isDelete) {
          this.clearInvoiceIncludedQuotes()
          this.quotesTotals.forEach(function (q) {
            if (vm.invoice && q.invoiceId === vm.invoice.invoiceId) {
              vm.addInvoiceIncludedQuotes(_cloneDeep(q))
            }
          })
        }
      }
    },
    includeQuote(quoteTotal, checked) {
      // console.log(quoteTotal)
      if (checked) {
        this.addInvoiceIncludedQuotes(quoteTotal)
        this.invoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, quoteTotal.quoteId)
      } else {
        this.removeInvoiceIncludedQuotes(quoteTotal)
        const index = this.invoice.includedQuotes.findIndex((quoteId) => quoteId === quoteTotal.quoteId)
        this.invoice.includedQuotes.splice(index, 1)
      }
    },
    async toggleGstInvoice(checked) {
      // this.isGstInvoiceOn = !this.isGstInvoiceOn
      if (checked) {
        // Create new GST invoice
        this.gstInvoice = await QuoteInvoiceService.getNewEntity()
        if (this.invoice) {
          this.gstInvoice.invoiceNo = this.invoice.invoiceNo
          this.gstInvoice.gst = this.invoice.gst
        } else {
          this.gstInvoice.gst = this.innerValue.gst
        }
        const utcDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString()
        this.gstInvoice.invoiceDate = utcDate.split('.')[0] + 'Z'
        this.gstInvoice.totalExGst = this.gstInvoice.gst
        this.gstInvoice.totalIncGst = this.gstInvoice.gst
        this.gstInvoice.invoiceType = InvoiceTypes.Gst
        this.gstInvoice.assetId = ''
        this.gstInvoice.assetType = ''
        this.gstInvoice.invoicePayingType = 1
        this.gstInvoice.includedQuotes.splice(this.gstInvoice.includedQuotes.length, 1, this.innerValue.quoteId)
        this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.gstInvoice)
        this.fixedGst = true
        this.invoice.gst = 0
      } else {
        // Delete/Remove existing GST invoice
        if (this.invoice.isNew) {
          this.fixedGst = false
          this.recalculateTotals()
        } else {
          this.invoice.gst = this.baseInvoice.gst
          this.fixedGst = this.invoice.gst !== this.calculateGst()
        }
        if (this.gstInvoice.isNew) {
          const index = this.innerValue.invoices
            .map(function (obj) {
              return obj.quoteItemId
            })
            .indexOf(this.gstInvoice.invoiceId)
          this.innerValue.invoices.splice(index, 1)
          this.gstInvoice = null
        } else {
          this.gstInvoice.deleted = true
          this.gstInvoice.isDeleted = true
        }
        this.gstAssetFilter.assetId = Guid.empty()
        this.selectedGstAsset = null
      }
      this.getGstAssetDropdown()
    },
    async getGstAssets() {
      const insurerAssetFilter = {
        assetId: this.innerValue.insurerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      const customerAssetFilter = {
        assetId: this.innerValue.customerId,
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 1
      }
      let insurers
      let customers
      ;[insurers, customers] = await Promise.all([
        QuickInvoiceService.getAssetDropdownProto(insurerAssetFilter),
        QuickInvoiceService.getAssetDropdownProto(customerAssetFilter)
      ])
      this.insurerAsset = insurers[0]
      this.customerAsset = customers[0]
    },
    async getGstAssetDropdown() {
      this.isGstAssetLoading = true
      this.gstAssets = await QuickInvoiceService.getAssetDropdownProto(this.gstAssetFilter)
      if (this.gstAssetFilter.assetId !== Guid.empty()) {
        this.selectedGstAsset = this.gstAssets.find((c) => c.assetId === this.gstAssetFilter.assetId)
      }
      this.isGstAssetLoading = false
    },
    getGstAssetDropdownFilter: _debounce(async function (query) {
      this.isGstAssetLoading = true
      this.gstAssetFilter.assetName = query
      this.gstAssets = await QuickInvoiceService.getAssetDropdownProto(this.gstAssetFilter)
      this.isGstAssetLoading = false
    }, 500),
    async selectGstAsset(selected) {
      this.gstInvoice.assetId = selected.assetId
      this.gstInvoice.assetType = selected.assetType
      this.gstInvoice.invoiceGlCode = selected.assetGlCode
      this.gstInvoice.assetName = selected.assetName
      this.gstAssetFilter.assetId = selected.assetId
    },
    removeGstAsset(removed) {
      this.gstAssetFilter.assetId = Guid.empty()
      this.gstInvoice.assetType = ''
      this.gstInvoice.invoiceGlCode = ''
      this.gstInvoice.assetId = Guid.empty()
      this.gstInvoice.assetName = ''
      this.getAssetDropdown()
    },
    reloadInvoice() {
      // console.log('reloadInvoice')
      this.innerValue = this.value
      this.getIncludedQuotesTotals()
      this.getInvoice()
    },
    resetOverrides() {
      this.overrides.labourTotal = 0
      this.overrides.rrTotal = 0
      this.overrides.repairTotal = 0
      this.overrides.rwaTotal = 0
      this.overrides.paintTotal = 0
      this.overrides.fgTotal = 0
      this.overrides.crushTotal = 0
      this.overrides.cdTotal = 0
      this.overrides.mechTotal = 0
      this.overrides.pdrRrTotal = 0
      this.overrides.pdrReTotal = 0
    },
    showChangeInvoiceNoModal() {
      this.isInvoiceNoSearchModalActive = true
    },
    changeInvoiceNo(invoiceNo) {
      ;(this.isInvoiceNoSearchModalActive = false), (this.invoice.invoiceNo = invoiceNo)
      this.invoice.invoiceNoRef = `${this.invoice.invoiceNo}/1`
      this.innerValue.invoices.forEach((inv) => {
        inv.invoiceNo = invoiceNo
      })
    },
    invoiceDateInputEvent(event) {
      this.selectedInvoiceDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.is-bottom {
  height: 280px;
  align-items: flex-end;
  align-self: stretch;
  justify-self: stretch;
  // flex-grow: 10;
  // flex-wrap: unset;
  // justify-self: flex-end;
  // align-content: flex-end;
}
</style>
